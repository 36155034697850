import React from 'react'
import { IRS_CORNER } from '../../components/constant'
import Layout from '../../components/layout'
import PageStructure from '../../components/pagestructure'
import SEO from '../../components/seo'
import IMGCamera from '../../images/camera.jpg'
const Photography = () => {
    return (
        <div>
            <Layout>
                <SEO title="Photography" />
                <PageStructure
                    image={IMGCamera}
                    title="Photography"
                    desc="TESTIG"
                    mode={IRS_CORNER}
                />
            </Layout>
        </div>
    )
}

export default Photography
